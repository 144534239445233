<template>
  <div>
    <v-row v-if="!files.length" class="info-message">
      <div>No files to show</div>
    </v-row>
    <v-row no-gutters>
      <v-col
        no-gutters
        v-for="file in files"
        :key="file.id"
        align="center"
        class="image-container-col"
      >
        <div
          v-if="isImage(file)"
          class="image-container"
          :style="`background-image: url(${`${serverHost}/api/inquiries/file_show/${file.upload_name}`});`"
          @click="
            handlePreview(
              `${serverHost}/api/inquiries/file_show/${file.upload_name}`
            )
          "
        >
          <div class="more-vert" @click="openActions(file.id, $event)">
            <v-icon class="more-vert-icon">mdi-dots-vertical</v-icon>
          </div>
          <div v-if="file.id === activeActionsId" class="actions-container">
            <div class="actions-list" @click="download(file, $event)">
              Download
            </div>
            <div
              v-if="isSupportedThumbnail(file.upload_name) && isBrandOrCreator"
              class="actions-list"
              @click="setDefault(file, $event)"
            >
              Set Default
            </div>
            <div
              v-if="
                !checkIsOneHourPast(file.created_at) &&
                (file.user_id === userId || isBrandOrCreator)
              "
              class="actions-list"
              @click="deleteFile(file, $event)"
            >
              Delete
            </div>
          </div>
          <center class="non-preview-content-container">
            <center class="non-preview-file-title">
              <truncate-hovering :text="file.title" :length="11" />
            </center>
            <center class="non-preview-text">
              {{ file.title.split(".").pop() }}
            </center>
          </center>
        </div>
        <div v-else class="non-preview-container">
          <div
            class="more-vert more-vert-enhanced"
            @click="openActions(file.id, $event)"
          >
            <v-icon class="more-vert-icon">mdi-dots-vertical</v-icon>
          </div>
          <div v-if="file.id === activeActionsId" class="actions-container">
            <div class="actions-list" @click="download(file, $event)">
              Download
            </div>
            <div
              v-if="isSupportedThumbnail(file.upload_name) && isBrandOrCreator"
              class="actions-list"
              @click="setDefault(file, $event)"
            >
              Set Default
            </div>
            <div
              v-if="
                !checkIsOneHourPast(file.created_at) &&
                (file.user_id === userId || isBrandOrCreator)
              "
              class="actions-list"
              @click="deleteFile(file, $event)"
            >
              Delete
            </div>
          </div>
          <center>
            <v-icon class="non-preview-icon">mdi-text-box-outline</v-icon>
          </center>
          <center class="non-preview-content-container">
            <center class="non-preview-file-title">
              <truncate-hovering :text="file.title" :length="11" />
            </center>
            <center class="non-preview-text">
              {{ file.title.split(".").pop() }}
            </center>
          </center>
        </div>
        <div class="date-show">
          {{ $moment(file.created_at).format("HH:mm, D M Y") }}
        </div>
      </v-col>
    </v-row>
    <popup-window
      :active="showPreview"
      :fullScreen="true"
      maxWidth="fit-content"
      width="fit-content"
      @popup-close="handlePreview()"
      @popup-back="handlePreview()"
    >
      <div>
        <img :src="previewingFile" style="max-width: 500px" />
      </div>
    </popup-window>
  </div>
</template>
<script>
import PopupWindow from "./base/PopupWindow";
// import restAdapter from "../../restAdapter";
import utils from "../utils";
import { isImageFile } from "../utils/media-type";
import * as conf from "../conf.yml";

export default {
  name: "ChatFilesContainer",
  components: {
    PopupWindow,
  },
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    userId: {
      type: [Number, String],
      required: true,
    },
    isBrandOrCreator: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    serverHost: conf.default.server.host,
    activeActionsId: "",
    showPreview: false,
    previewingFile: "",
    acceptedThumbnailTypes: utils.acceptedThumbnailTypes,
  }),
  methods: {
    checkIsOneHourPast(timestamp) {
      return !(
        Math.floor(Date.now() - new Date(timestamp).getTime()) <
        1 * 60 * 60 * 1000
      );
    },
    openActions(id, event) {
      event.stopPropagation();
      if (this.activeActionsId === id) {
        this.activeActionsId = "";
      } else {
        this.activeActionsId = id;
      }
    },
    handlePreview(src) {
      if (src) {
        this.showPreview = true;
        this.previewingFile = src;
      } else {
        this.showPreview = false;
        this.previewingFile = "";
      }
    },
    isImage(file) {
      return isImageFile(file);
    },
    getFileExtension(filename) {
      return (
        filename.match(/\.[0-9a-z]+$/i) &&
        filename.match(/\.[0-9a-z]+$/i)[0].toLowerCase()
      );
    },
    isSupportedThumbnail(filename) {
      const supportedThumbnailTypes = this.acceptedThumbnailTypes.split(",");
      return supportedThumbnailTypes.includes(this.getFileExtension(filename));
    },
    deleteFile(file, event) {
      event.stopPropagation();
      this.$emit("delete", file.upload_name);
    },
    setDefault(file, event) {
      event.stopPropagation();
      this.$emit("set-default", file.upload_name);
    },
    download(file, event) {
      event.stopPropagation();
      this.$emit("download", file);
    },
  },
};
</script>

<style lang="scss" scoped>
.image-container-col {
  justify-content: center;
  padding: 15px !important;
  flex-grow: 0;
}
.info-message {
  justify-content: center;
  padding: 10px !important;
  color: $infoMessage;
}
.image-container {
  position: relative;
  width: 75px;
  height: 75px;
  background-size: 100%;
  border: 0.5px solid $primaryDark;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}
.more-vert {
  background: $paginateText;
  width: 16px;
  height: 16px;
  float: right;
  border-radius: 50%;
  cursor: pointer;
}
.more-vert:hover {
  border: 0.5px solid $primaryDark;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}
.more-vert-enhanced {
  position: absolute;
  right: 0;
  top: 0;
}
.more-vert-icon {
  font-size: 15px;
  vertical-align: unset;
}
.non-preview-container {
  width: 75px;
  height: 75px;
  border: 0.5px solid $primaryDark;
  border-radius: 5px;
  position: relative;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}
.non-preview-icon {
  color: $primary;
  margin-top: 10px;
  font-size: 30px;
}
.non-preview-text {
  white-space: nowrap;
  overflow: hidden;
  margin: 0px;
  line-height: normal;
  color: $nonPreviewText;
  font-size: 10px;
  font-weight: bold;
  background-color: #fff;
}
.actions-container {
  position: absolute;
  right: 0;
  top: 20px;
  width: 100px;
  height: auto;
  overflow: visible;
  z-index: 10;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 2.96703px 13.8462px 11.8681px rgba(0, 0, 0, 0.1);
}
.date-show {
  float: left;
  font-size: 8px;
  color: $primaryDark;
}
.actions-list {
  overflow: visible;
  color: $infoMessage;
  cursor: pointer;
}
.actions-list:hover {
  color: $tableBorder;
  background: rgba(255, 255, 255, 0.95);
}
.non-preview-content-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 10px;
  font-weight: bold;
  padding: 1px;
  opacity: 0.7;
}
.v-application {
  line-height: 1.3;
}
.non-preview-file-title {
  overflow: hidden;
  white-space: nowrap;
  background-color: #fff;
}
</style>
