<template>
  <div>
    <v-row class="info-message">
      <div v-if="pinned.length">
        Showing a total of {{ pinned.length }} pinned messages.
      </div>
      <div v-else>No pinned messages</div>
    </v-row>
    <v-row no-gutters>
      <v-col
        no-gutters
        v-for="message in pinned"
        :key="message._id"
        align="center"
        class="pin-container-col"
      >
        <div
          class="pinned-container"
          :style="`width: ${message.content ? 'max-content' : 'fit-content'};`"
          @click="trackPin(message)"
        >
          <div v-if="message.file">
            <div
              v-if="isImage(message.file)"
              class="image-container"
              :style="`background-image: url(${message.file.url});`"
            >
              <div
                v-if="!message.content && message.canUnpin"
                class="more-vert more-vert-enhanced"
                @click="unpinPinned(message._id, $event)"
              >
                <v-icon class="more-vert-icon star-icon">mdi-pin-off</v-icon>
              </div>
              <center class="non-preview-content-container">
                <center class="non-preview-file-title">
                  <truncate-hovering :text="message.file.name" :length="11" />
                </center>
                <center class="pinned-non-preview-text">
                  {{ message.file.extension }}
                </center>
              </center>
            </div>
            <div v-else>
              <div class="non-preview-container">
                <div
                  v-if="!message.content && message.canUnpin"
                  class="more-vert more-vert-enhanced"
                  @click="unpinPinned(message._id, $event)"
                >
                  <v-icon class="more-vert-icon star-icon">mdi-pin-off</v-icon>
                </div>
                <center>
                  <v-icon class="pinned-non-preview-icon"
                    >mdi-text-box-outline</v-icon
                  >
                </center>
                <center class="pinned-text-container">
                  <center class="pinned-file-name">
                    {{ message.file.name }}
                  </center>
                  <center class="pinned-non-preview-text">
                    {{ message.file.extension }}
                  </center>
                </center>
              </div>
            </div>
          </div>
          <div
            v-if="message.content"
            class="pinned-content-container"
            :style="`width: ${!message.file ? '170px' : '95px'};`"
          >
            <div style="position: relative">
              <div
                class="more-vert more-vert-enhanced"
                v-if="message.canUnpin"
                @click="unpinPinned(message._id, $event)"
              >
                <v-icon class="more-vert-icon star-icon">mdi-pin-off</v-icon>
              </div>
              <div class="pinned-content">{{ message.content }}</div>
            </div>
          </div>
        </div>
        <div class="date-show">
          {{ $moment(message.created_at).format("HH:mm, D M Y") }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
// import restAdapter from "../../restAdapter";
import { isImageFile } from "../utils/media-type";

export default {
  name: "ChatPinsContainer",
  props: {
    pinned: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isImage(file) {
      return isImageFile(file);
    },
    unpinPinned(messageId, event) {
      event.stopPropagation();
      this.$emit("unpinned", messageId);
    },
    trackPin(message) {
      this.$emit("track-pin", message);
    },
  },
};
</script>

<style lang="scss" scoped>
.pin-container-col {
  justify-content: center;
  padding: 15px !important;
  flex-grow: 0;
  width: calc((100% / 4) - 5px);
}
.info-message {
  justify-content: center;
  padding: 10px !important;
  color: $infoMessage;
}
.image-container {
  position: relative;
  width: 75px;
  height: 75px;
  background-size: 100%;
  border: 0.5px solid $primaryDark;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}
.more-vert {
  background: $paginateText;
  width: 16px;
  height: 16px;
  float: right;
  border-radius: 50%;
  cursor: pointer;
}
.more-vert:hover {
  border: 0.5px solid $primaryDark;
  width: 18px;
  height: 18px;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));

  .more-vert-icon {
    font-size: 17px;
  }
}
.more-vert-enhanced {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.more-vert-icon {
  font-size: 15px;
  vertical-align: unset;
}
.star-icon {
  color: $tableBorder;
}
.non-preview-container {
  width: 75px;
  height: 75px;
  border: 0.5px solid $primaryDark;
  border-radius: 5px;
  position: relative;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}
.pinned-non-preview-icon {
  color: $primary;
  margin-top: 10px;
  font-size: 30px;
}
.pinned-text-container {
  position: absolute;
  bottom: 0;
  width: 65px;
}
.pinned-file-name {
  overflow: hidden;
  white-space: nowrap;
}
.pinned-non-preview-text {
  white-space: nowrap;
  overflow: hidden;
  margin: 0px;
  line-height: normal;
  color: $nonPreviewText;
  font-size: 10px;
  font-weight: bold;
  background-color: #fff;
}
.pinned-content {
  color: $primaryDark;
}
.date-show {
  float: left;
  font-size: 8px;
  color: $primaryDark;
}
.pinned-container {
  display: flex;
  background: $paginateText;
  height: 75px;
  overflow: hidden;
}
.non-preview-content-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 10px;
  font-weight: bold;
  padding: 1px;
  opacity: 0.7;
}
.v-application {
  line-height: 1.3;
}
.non-preview-file-title {
  overflow: hidden;
  white-space: nowrap;
  background-color: #fff;
}
.pinned-content-container {
  text-align: left;
  margin: 5px;
  overflow: hidden;
}
</style>
