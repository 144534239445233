<template>
  <div>
    <v-card flat class="timeline-card-container">
      <timeline
        :minimal="true"
        :collectionId="collectionId"
        :refreshTimeline="refreshTimeline"
        @stepper-click="$emit('stepper-click', $event)"
      />
    </v-card>
    <v-card
      flat
      class="chat-card-container"
      style="background-color: transparent"
    >
      <v-row justify="center">
        <v-col
          cols="12"
          style="padding-left: 0px; padding-right: 0px; padding-top: 0px"
        >
          <chat
            style="padding-top: 0"
            :collectionId="collectionId"
            :assigned-suppliers="assignedSuppliers"
            :startingRoomId="Number($route.query.room_id)"
            :userId="userId"
            :isBrandOrCreator="isBrandOrCreator"
            @set-default="$emit('set-default', $event)"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Timeline from "@/components/Inquiry/Timeline.vue";
import Chat from "@/components/Chat.vue";

export default {
  name: "ChatTab",
  components: {
    Timeline,
    Chat,
  },
  props: {
    collectionId: {
      type: Number,
      required: true,
    },
    startingRoomId: {
      type: Number,
      required: true,
    },
    assignedSuppliers: {
      type: Array,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    isBrandOrCreator: {
      type: Boolean,
      required: true,
    },
    refreshTimeline: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline-card-container {
  margin: 0px;
  margin-top: -10px;
  height: auto;
}
.chat-card-container {
  margin: 0px;
  margin-top: 0px !important;
  padding: 0px;
}
</style>
