<template>
  <v-col v-if="this.rooms.length" id="chat">
    <popup-window
      :active="showInviteUserForm"
      title="Add room participants"
      @popup-close="resetForms"
      @popup-back="resetForms"
    >
      <div>
        <users-list-view
          :searchField="true"
          placeholder="Search business name"
          :membersList="nonSelectedList"
          :selectedList="selectedList"
          @add-item="addUserToSelectedList"
          @remove-item="removeUserFromSelectedList"
        />
        <md-progress-spinner
          v-if="creatingChat"
          md-mode="indeterminate"
        ></md-progress-spinner>
        <div class="createChatButton">
          <ui-button
            class="primary long"
            @click="inviteUser"
            :disabled="creatingChat"
            title="Add"
          />
        </div>
      </div>
    </popup-window>

    <popup-window
      :active="showChatParticipants"
      title="Chat participants"
      @popup-close="resetForms"
      @popup-back="resetForms"
    >
      <div>
        <chat-participants
          :searchField="true"
          placeholder="Search contact / business name"
          :membersList="roomParticipants"
        />
        <md-progress-spinner
          v-if="creatingChat"
          md-mode="indeterminate"
        ></md-progress-spinner>
      </div>
    </popup-window>

    <popup-window
      :active="showRemoveUserForm"
      @popup-close="resetForms"
      @popup-back="resetForms"
    >
      <div style="max-height: 400px; overflow-y: scroll">
        <users-list-view
          :searchField="true"
          placeholder="Search business name"
          :chatroomName="chatRoomName"
          :membersList="roomParticipants"
          :currentUserOrganization="this.currentUserOrganizationId"
          :selectedList="selectedList"
          :remove-one-by-one="true"
          :navigate-add-user="true"
          @add-item="addUserToSelectedList"
          @remove-item="removeUserFromSelectedList"
          @navigate-add-user="inviteUserHandler()"
        />
        <md-progress-spinner
          v-if="creatingChat"
          md-mode="indeterminate"
        ></md-progress-spinner>
        <div v-if="selectedList.length" class="createChatButton">
          <ui-button
            class="danger long"
            @click="removeUser"
            :disabled="creatingChat"
            title="Remove"
          />
        </div>
      </div>
    </popup-window>

    <popup-window
      :active="showSeenListPopup"
      title="Seen By"
      width="750px"
      @popup-close="resetForms"
      @popup-back="resetForms"
    >
      <div>
        <seen-list
          :searchField="true"
          placeholder="Search contact / business name"
          :membersList="seenMembersList"
        />
        <md-progress-spinner
          v-if="creatingChat"
          md-mode="indeterminate"
        ></md-progress-spinner>
      </div>
    </popup-window>

    <popup-window
      :active="showCreateChatForm"
      title="Add room participants"
      @popup-close="resetForms"
      @popup-back="backToPreviousSection"
    >
      <transition name="slide-left">
        <div v-if="currentSection === 'selectUsers'">
          <users-list-view
            :searchField="true"
            placeholder="Search business name"
            :membersList="nonSelectedList"
            :selectedList="selectedList"
            @add-item="addUserToSelectedList"
            @remove-item="removeUserFromSelectedList"
          />
          <md-progress-spinner
            v-if="creatingChat"
            md-mode="indeterminate"
          ></md-progress-spinner>
          <div class="input-validation">
            <div v-if="errorMessages.selectUsers" class="error-message">
              Please select at least two companies to create chat
            </div>
          </div>
          <div class="createChatButton">
            <ui-button
              class="primary long"
              @click="goToAddRoomName"
              :disabled="creatingChat"
              title="Next"
            />
          </div>
        </div>
        <div v-else-if="currentSection === 'addRoomName'">
          <div class="input-group">
            <input
              v-if="selectedList.length >= 2"
              v-model="roomName"
              class="text-field"
              type="text"
              placeholder="Room Name"
              id="room_name"
              name="room_name"
              @input="validateRoomName"
            />
            <div v-if="errorMessages.roomName" class="error-message">
              {{ errorMessages.roomName }}
            </div>
          </div>
          <div class="createChatButton">
            <ui-button
              class="primary long"
              @click="createChat"
              :disabled="creatingChat || !!errorMessages.roomName"
              title="Create Chat"
            />
          </div>
        </div>
      </transition>
    </popup-window>

    <chat-window
      :height="chatHeight ? chatHeight + 'px' : '75vh'"
      :current-user-id="currentUserId"
      :rooms="rooms"
      :room-types="roomTypes"
      :messages="messages"
      :rooms-loaded="roomsLoaded"
      :messages-loaded="messagesLoaded"
      :active-tab="activeChatTab"
      @send-message="sendMessage"
      @fetch-messages="fetchMessages"
      @change-tab="activeChatTab = $event"
      :single-room="false"
      :show-reaction-emojis="false"
      :show-audio="false"
      :show-emojis="true"
      :messageActions="messageActions"
      @message-action-handler="messageActionHandler"
      @open-file="openFileHandler"
      @delete-message="deleteMessage"
      :accepted-files="acceptedFileTypes"
      :max-file-size="maxFileSize"
      :max-files-sum-size="maxFileSize"
      @limit-size-exceeded="limitSizeExceeded"
      @add-room="showCreateChat"
      :show-add-room="true"
      :room-id="startingRoomId"
      @show-reply-message="showReplyMessage"
      @rename-room="renameRoom"
    >
      <template v-slot:files-tab-content>
        <chat-files-container
          :user-id="userId"
          :files="files"
          :is-brand-or-creator="isBrandOrCreator"
          @delete="deleteFile"
          @set-default="$emit('set-default', $event)"
          @download="downloadFile"
        />
      </template>
      <template v-slot:pinned-tab-content>
        <chat-pins-container
          :pinned="pinnedMessages"
          @unpinned="unpinMessage($event)"
          @track-pin="showSelectedMessage($event)"
        />
      </template>
      <template v-slot:message-search>
        <div class="search-messages">
          <div class="search-message-field">
            <magnify-icon fill="#7E6E95" />
            <input
              v-model="searchMessage"
              class="search-input"
              type="text"
              placeholder="Search messages "
              id="search-message"
              name="search-message"
              v-on:input="searchChatMessages"
            />
          </div>
          <div v-if="searchMessage" class="mx-auto search-result">
            <div v-if="searching" class="spinner-box">
              <md-progress-bar md-mode="indeterminate"></md-progress-bar>
              <label>Getting matching messages</label>
            </div>
            <v-subheader v-else-if="!searching && matchingMessages.length === 0"
              >No matching messages found
            </v-subheader>
            <div v-if="!searching" class="search-results-container">
              <div
                v-for="message in matchingMessages"
                :key="message._id"
                @click="showSelectedMessage(message)"
                class="search-result-item"
              >
                <v-icon class="search-icon">mdi-magnify</v-icon>
                <div class="search-result-item-text">
                  <div>{{ message.content }}</div>
                  <div>
                    {{
                      message.timestamp +
                      ", " +
                      message.date +
                      (message.username ? " by " + message.username : "")
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="scrolling || notFound" class="mx-auto search-result">
            <div v-if="scrolling" class="spinner-box">
              <md-progress-bar md-mode="indeterminate"></md-progress-bar>
              <label>Searching</label>
            </div>
            <v-subheader v-if="notFound" class="notFound-alert"
              >Message not found
            </v-subheader>
          </div>
        </div>
      </template>
      <template v-slot:room-members>
        <div
          v-if="
            roomParticipants &&
            roomParticipants.length &&
            currentRoom.room.roomType !== 4
          "
          class="room-members-button"
          @click="menuActionHandler()"
        >
          <div
            v-for="(participant, index) in roomParticipants.slice(0, 3)"
            :key="participant.id"
            class="participant-icon"
            :style="`z-index: ${5 - index};`"
          >
            <v-icon v-if="!participant.avatar" size="15" color="#473068"
              >mdi-domain</v-icon
            >
            <v-avatar v-else size="20">
              <v-img :src="participant.avatar" />
            </v-avatar>
          </div>
          <div class="participants-length">{{ roomParticipants.length }}</div>
        </div>
      </template>
    </chat-window>
  </v-col>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import ChatWindow from "vue-advanced-chat";
import auth from "../auth";
import restAdapter from "../restAdapter";
import * as conf from "../conf.yml";
import notification from "../notification";
import utils from "../utils";
import debounce from "lodash/debounce";
import UsersListView from "../components/UsersListView.vue";
import ChatParticipants from "../components/ChatParticipants.vue";
import SeenList from "../components/SeenList.vue";
import routes from "../router/routes";
import ChatFilesContainer from "./ChatFilesContainer.vue";
import ChatPinsContainer from "./ChatPinsContainer.vue";

export default {
  name: "Chat",
  components: {
    ChatWindow,
    UsersListView,
    ChatParticipants,
    SeenList,
    ChatFilesContainer,
    ChatPinsContainer,
  },
  props: {
    collectionId: {
      type: Number,
      required: true,
    },
    assignedSuppliers: {
      type: Array,
      default: () => [],
    },
    startingRoomId: {
      type: [Number, null],
    },
    activeTab: {
      type: String,
      default: "",
    },
    activeCard: {
      type: [Number, null],
    },
    userId: {
      type: Number,
      default: null,
    },
    isBrandOrCreator: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    baseUrl: conf.default.server.host,
    chatHeight: 0,
    messageIdFromQuery: null,
    ignoreQuery: false,
    loadingMsgId: 0,
    commonChatImage: require(`../assets/chat/group-chat.webp`),
    person: require(`../assets/chat/person.webp`),
    directChatImage: require(`../assets/chat/direct-chat.webp`),
    notFound: false,
    roomsLoaded: false,
    messagesLoaded: false,
    rooms: [],
    count: [],
    messages: [],
    files: [],
    pinnedMessages: [],
    currentUserId: 0,
    currentRoom: {},
    showSeenListPopup: false,
    seenMembersList: [],
    pagination: 1,
    searching: false,
    scrolling: false,
    lastSeenMsg: null,
    updateSeen: true,
    showInviteUserForm: false,
    showRemoveUserForm: false,
    showChatParticipants: false,
    currentSection: null,
    membersList: [],
    showCreateChatForm: false,
    isInitialCall: false,
    selectedList: [],
    selectedUsersIds: [],
    acceptedFileTypes: utils.acceptedFileTypes,
    searchMessage: null,
    matchingMessages: [],
    initialFetch: true,
    maxFileSize: 50 * 1024 * 1024,
    creatingChat: false,
    currentUserOrganizationId: null,
    searchingForMessage: false,
    messagesFetched: false,
    messageActions: [
      {
        name: "replyMessage",
        title: "Reply",
      },
      {
        name: "deleteMessage",
        title: "Delete Message",
        onlyMe: true,
        disappearAfter: 1 * 60 * 60 * 1000,
      },
      {
        name: "pinMessage",
        title: "Pin Message",
      },
      {
        name: "seenList",
        title: "Info",
        onlyMe: true,
      },
    ],
    errorMessages: {
      selectUsers: false,
      roomName: false,
    },
    activeChatTab: "chat",
    chatTabs: {
      CHAT: "chat",
      FILES: "files",
      PINNED: "pinned",
    },
    roomParticipants: [],
    roomTypes: [
      {
        id: 1,
        name: "Group Messages",
        type: "group",
        showAddRoom: true,
        unreadCount: 0,
      },
      { id: 2, name: "Direct Messages", type: "direct", unreadCount: 0 },
    ],
  }),
  created() {
    window.addEventListener("resize", this.screenSizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.screenSizeHandler);
  },
  mounted() {
    this.screenSizeHandler();
    this.currentUserId = auth.user.id;
    this.currentUserOrganizationId = auth.user.account.organization_registry_id;
    this.fetchRooms();
  },
  watch: {
    activeTab: {
      handler: function (value) {
        this.updateSeen = value === "order" || value === "collection";
        this.sendSeenStatus(this.currentRoom.room.roomId);
      },
    },
    lastSeenMsg: {
      handler: function (value) {
        if (value && value.msgId) {
          this.updateSeenStatus(value.roomId, value.msgId);
        }
      },
    },
    currentRoom(currentRoom, previousRoom) {
      this.getRoomParticipants();
      if (previousRoom && previousRoom.room) {
        window.Echo.leave(`chat.${previousRoom.room.roomId}`);
        window.Echo.leave(`deleteMessage.${previousRoom.room.roomId}`);
        window.Echo.leave(`seenMessage.${previousRoom.room.roomId}`);
      }
      window.Echo.private(`chat.${currentRoom.room.roomId}`).listen(
        ".privateMessageSent",
        (e) => {
          const recievedMessage = this.formatMessage(e.messageData);
          if (
            !this.messages
              .map((message) => message._id)
              .includes(recievedMessage._id)
          ) {
            auth.user.id !== recievedMessage.senderId &&
              this.messages.push(recievedMessage);
            e.messageData.fetchRooms && this.fetchRooms();
            !e.messageData?.fetchRooms &&
              this.updateRoomLastMessage(currentRoom.room.roomId, {
                ...recievedMessage,
                content: recievedMessage.content || recievedMessage.name || "",
                id: recievedMessage._id,
              });
            auth.user.id !== recievedMessage.senderId &&
              this.sendSeenStatus(currentRoom.room.roomId, recievedMessage._id);
          }
        }
      );
      window.Echo.private(`deleteMessage.${currentRoom.room.roomId}`).listen(
        ".privateMessageDelete",
        (e) => {
          this.messages.forEach((msg) => {
            if (msg._id === e.messageData.message_id) {
              msg["deleted"] = true;
              this.updateRoomLastMessage(
                currentRoom.room.roomId,
                { id: e.messageData.message_id },
                true
              );
            }
          });
        }
      );

      window.Echo.private(`seenMessage.${currentRoom.room.roomId}`).listen(
        ".privateMessageSeen",
        (e) => {
          if (e.updateMultiple) {
            this.messages.forEach((msg) => {
              if (msg._id < e.lastSeenMsg) {
                msg["seen"] = true;
              }
            });
          } else {
            this.lastSeenMsg = e.lastSeenMsg && {
              roomId: currentRoom.room.roomId,
              msgId: e.lastSeenMsg,
            };
            if (!e.lastSeenMsg) {
              this.messages = this.messages.map((msg) => ({
                ...msg,
                seen: true,
              }));
            }
          }
        }
      );
    },
    messagesFetched: function (value) {
      if (value && this.searchingForMessage) {
        setTimeout(() => {
          this.scrollToMessage(this.searchingForMessage);
        }, 1000);
      }
    },
    selectedList: {
      handler(newVal) {
        if (this.isInitialCall) {
          this.errorMessages.selectUsers = newVal.length < 2;
        } else {
          this.errorMessages.selectUsers = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    screenSizeHandler() {
      const viewBoxHeight =
        (window.innerHeight < 650 ? 650 : window.innerHeight) -
        (75 + 48 + 118 + 58);
      this.chatHeight = viewBoxHeight;
    },
    validateRoomName() {
      if (this.roomName.length > 40) {
        this.errorMessages.roomName = "Room name cannot exceed 40 characters.";
      } else {
        this.errorMessages.roomName = "";
      }
    },
    async fetchRooms() {
      this.roomsLoaded = false;

      restAdapter.get(`/api/chats/${this.collectionId}`).then(({ data }) => {
        const mergedRooms = [...data.chat_list, ...data.removed_chat_list];

        const formatData = mergedRooms.map((room) => {
          const roomType = room.chat_type;
          const lastMessage = room.latest_message
            ? {
                id: room.latest_message.id,
                content:
                  room.latest_message.message ||
                  room.latest_message.attachment_name,
                timestamp: this.$moment(room.latest_message.created_at).format(
                  "hh:mm A"
                ),
                createdAt: room.latest_message.created_at,
              }
            : null;

          return {
            roomId: room.id,
            unreadCount: room.unread_count.original.unread_count,
            roomName:
              room.chat_name ||
              (roomType === 3
                ? room.members.map((m) => m.company_name).join(", ")
                : ""),
            lastMessage,
            collectionId: room.collection_id,
            type: roomType === 4 ? "direct" : "group",
            roomType,
            createdAt: room.created_at,
            isEditable: true,
            avatar: room.avatar
              ? `${this.baseUrl}/api/${room.avatar}`
              : this.getChatImage(room),
            removedRoomStatus: room.removed,
            users: room.members.map((member) => {
              return {
                _id: member.id,
                username: member.first_name + " " + member.last_name,
                company: member.company_name,
                avatar: `${this.baseUrl}/api/user/avatar/${member.id}`,
              };
            }),
          };
        });

        formatData.sort((a, b) => {
          if (a.roomType === 2 && b.roomType !== 2) return -1;
          if (a.roomType !== 2 && b.roomType === 2) return 1;
          const timeA = a.lastMessage
            ? new Date(a.lastMessage.createdAt)
            : new Date(a.createdAt);
          const timeB = b.lastMessage
            ? new Date(b.lastMessage.createdAt)
            : new Date(b.createdAt);
          return timeB - timeA;
        });

        this.rooms = formatData;

        this.roomsLoaded = true;
        this.$emit("chat-status", this.rooms.length > 0);

        const groupUnreadCount = this.rooms.reduce((counts, room) => {
          counts[room.type] = (counts[room.type] || 0) + room.unreadCount;
          return counts;
        }, {});

        this.roomTypes = this.roomTypes.map((type) => ({
          ...type,
          unreadCount: groupUnreadCount[type.type] || 0,
        }));
      });
    },
    updateRoomLastMessage(roomId, message, isDeletion = false) {
      if (isDeletion) {
        this.rooms = this.rooms.map((room) => {
          if (
            room.roomId === roomId &&
            room.lastMessage &&
            room.lastMessage.id === message.id
          ) {
            return {
              ...room,
              lastMessage: {
                ...room.lastMessage,
                content: "",
              },
            };
          }
          return room;
        });
      } else {
        this.rooms = this.rooms.map((room) => {
          if (room.roomId === roomId) {
            return {
              ...room,
              lastMessage: {
                id: message.id,
                content: message.content,
                timestamp: message.timestamp,
                createdAt: new Date(),
              },
            };
          }
          return room;
        });
      }

      this.rooms.sort((a, b) => {
        if (a.roomType === 2 && b.roomType !== 2) return -1;
        if (a.roomType !== 2 && b.roomType === 2) return 1;
        const timeA = a.lastMessage
          ? new Date(a.lastMessage.createdAt || a.lastMessage.timestamp)
          : new Date(a.createdAt);
        const timeB = b.lastMessage
          ? new Date(b.lastMessage.createdAt || b.lastMessage.timestamp)
          : new Date(b.createdAt);
        return timeB - timeA;
      });
    },
    async fetchMessages(room) {
      if (!room) {
        return;
      }
      this.messagesFetched = false;
      this.currentRoom = room;
      const currentRoomID = this.currentRoom.room.roomId;
      const isRoomReset = room.options && room.options.reset;

      this.rooms = this.rooms.map((data) => {
        if (data.roomId === room.room.roomId) {
          return {
            ...data,
            unreadCount: 0,
          };
        }
        return data;
      });

      this.rooms.forEach((roomy) => {
        if (roomy.roomId === room.room.roomId) {
          window.Echo.leave(
            `chatCount.${room.room.roomId}.${this.currentUserId}`
          );
        }

        if (roomy.roomId !== room.room.roomId) {
          window.Echo.private(
            `chatCount.${roomy.roomId}.${this.currentUserId}`
          ).listen(".unreadMessageCount", (e) => {
            roomy.unreadCount = e.data.original.unread_count;
            if (e.data.original.latest_unread) {
              this.updateRoomLastMessage(roomy.roomId, {
                id: e.data.original.latest_unread.id,
                content: e.data.original.latest_unread.content,
                timestamp: this.$moment(
                  e.data.original.latest_unread.created_at
                ).format("hh:mm A"),
              });
            }
          });
        }
      });

      this.refreshPinned(isRoomReset, room);

      if (this.$route.query && currentRoomID != this.$route.query.room_id) {
        this.ignoreQuery = true;
      }

      if (
        !this.ignoreQuery &&
        isRoomReset &&
        this.$route.query &&
        this.messageIdFromQuery === this.$route.query.msg &&
        currentRoomID == this.$route.query.room_id
      ) {
        this.messageIdFromQuery = null;
        return;
      }

      this.messageIdFromQuery = this.$route.query?.msg;

      this.fetchFiles();
      this.resetForms();

      // if message id is in query, show that message
      if (this.$route.query.msg && this.initialFetch) {
        this.initialFetch = false;
        this.pagination = 0;
        this.matchingMessages = [];
        this.messages = [];
        this.messagesLoaded = false;
        this.pagination++;
        await this.showSelectedMessage({ _id: this.$route.query.msg }, room);
        window.history.pushState({}, "", window.location.href.split("?")[0]);
        return;
      }

      if (isRoomReset) {
        // reset everything
        this.pagination = 0;
        this.matchingMessages = [];
        this.messages = [];
        this.messagesLoaded = false;
      }

      // get next page
      this.pagination++;

      // get next messages and push them
      const messagesResponse = await restAdapter.get(
        `/api/chats/${room.room.roomId}/view_chat?page=${this.pagination}`
      );
      const formattedMessages = messagesResponse.data.data.map((message) =>
        this.formatMessage(message)
      );

      if (this.currentRoom.room.roomId === room.room.roomId) {
        this.messages.unshift(...formattedMessages);
      }

      // if last page set messages loaded
      if (!messagesResponse.data.data.length) {
        this.messagesLoaded = true;
      }

      this.messagesFetched = true;
    },

    async fetchFiles() {
      const filesResponse = await restAdapter.get(
        `/api/chats/${this.currentRoom.room.roomId}/files`
      );
      this.files = filesResponse.data.files;
    },
    deleteFile(id) {
      if (confirm("Delete this file?")) {
        restAdapter.delete("/api/collections/file_delete/" + id).then(() => {
          notification.success("File deleted successfully");
          this.fetchFiles();
        });
      }
    },
    downloadFile(file) {
      window.open(
        `${conf.server.host}/api/collections/file_download/${file.upload_name}`
      );
    },
    async refreshPinned(isRoomReset, room) {
      if (!isRoomReset) return;

      // get pinned
      const messagesResponse = await restAdapter.get(
        `/api/chats/${room.room.roomId}/pin_list `
      );

      const pinnedMessagesResponse = messagesResponse.data.data.map((data) => {
        return {
          ...data,
          message: data.message.map((message) => {
            const fileName = data.file?.title.split(".")[0];
            const pinnedBy = data.pinned_by;
            return {
              ...message,
              attachment_name: fileName,
              pinned_by: pinnedBy,
            };
          }),
        };
      });

      const formattedPinnedMessages = pinnedMessagesResponse.map((message) =>
        this.formatMessage(message.message[0], true)
      );

      this.pinnedMessages = formattedPinnedMessages;
    },
    async sendMessage(data) {
      const formData = new FormData();
      formData.append("collection_id", this.collectionId);
      formData.append("message", data.content);

      if (data.replyMessage && data.replyMessage._id) {
        const replyMessageId = data.replyMessage._id;
        formData.append("reply_id", replyMessageId);
      }

      if (data.file && data.file.blob) {
        if (data.file.size > this.maxFileSize) {
          notification.error(
            `${data.file.name}.${data.file.extension} is too large to send`
          );
          return;
        }
        const file = new File(
          [data.file.blob],
          data.file.name + "." + data.file.extension,
          {
            type: data.file.type,
            lastModified: new Date().getTime(),
          }
        );
        formData.append("file", file);
      }

      this.loadingMsgId = this.loadingMsgId - 1;
      const loadingId = this.loadingMsgId;

      const returnedMessage = await restAdapter
        .post(`/api/chats/${data.roomId}/create_message`, formData, {
          onUploadProgress: (progressEvent) => {
            this.messages = this.messages.filter((msg) => msg._id != loadingId);
            const loadingScore =
              data.file && data.file.blob
                ? Math.floor((progressEvent.loaded * 100) / progressEvent.total)
                : undefined;
            const loadingMessage = {
              _id: loadingId,
              senderId: auth.user.id || 0,
              date: this.$moment().format("D MMM"),
              timestamp: this.$moment().format("HH:mm"),
              content: data.content,
              seen: false,
              uploading: data.file && data.file.blob,
              sending: true,
              uploadingScore: loadingScore,
              fileId:
                data.file && data.file.blob
                  ? data.file.name + "." + data.file.extension
                  : null,
              pageId: this.pagination,
            };

            this.messages.push(loadingMessage);
          },
        })
        .catch((error) => {
          if (data.file && data.file.blob) {
            notification.error(
              `${data.file.name + "." + data.file.extension} failed to upload`
            );
          }
        });

      this.messages = this.messages.filter((msg) => msg._id != loadingId);
      const formattedMessage = this.formatMessage(returnedMessage.data.data);
      this.messages.push(formattedMessage);
      this.messages.sort((a, b) => {
        if (a.roomType === 2 && b.roomType !== 2) return -1;
        if (a.roomType !== 2 && b.roomType === 2) return 1;
        return new Date(a.timestamp) - new Date(b.timestamp);
      });

      this.updateSeenStatus(
        data.roomId,
        this.lastSeenMsg && this.lastSeenMsg.msgId
      );
      this.lastSeenMsg = null;

      if (returnedMessage.data.data.attachment_id) {
        this.fetchFiles();
      }
    },
    showReplyMessage(message) {
      this.showSelectedMessage(message);
    },
    async messageActionHandler({ roomId, action, message }) {
      let isForAll = false;
      switch (action.name) {
        case "pinMessage":
          isForAll = false;
          break;
        case "pinForAll":
          isForAll = true;
          break;
        case "seenList": {
          this.resetForms();
          const response = await restAdapter.get(
            `/api/chat/messages/${message._id}/seen_list`
          );
          this.seenMembersList = response.data.seen_list;
          this.seenMembersList = this.seenMembersList.map((member) => {
            return {
              ...member.user,
              avatar: this.rooms
                .find((room) => room.roomId === this.currentRoom.room.roomId)
                ?.users?.find((user) => user._id === member.user.id)?.avatar,
              read_at: member.read_at,
            };
          });
          this.showSeenListPopup = true;
          break;
        }
      }

      if (!this.pinnedMessages.find((msg) => msg._id === message._id)) {
        this.pinnedMessages.push({
          ...this.messages.find((msg) => msg._id === message._id),
          canUnpin: true,
        });

        await restAdapter.post(`/api/chat/messages/${message._id}/pin`, {
          is_for_all: isForAll,
        });
      }
    },
    async menuActionHandler() {
      if (
        !this.currentRoom ||
        !this.currentRoom.room ||
        !this.currentRoom.room.roomId
      ) {
        return;
      }

      const action =
        this.currentRoom.room.roomType === 3
          ? "removeUser"
          : "viewParticipants";

      switch (action) {
        case "viewParticipants": {
          this.resetForms();
          const membersListData = await restAdapter.get(
            `/api/chats/${this.collectionId}/view_participants?chat_id=${this.currentRoom.room.roomId}`
          );
          this.membersList = membersListData.data.member_list;
          this.membersList = this.membersList.map((member) => {
            return {
              ...member,
              avatar: this.assignedSuppliers.find(
                (supplier) => supplier.id === member.id
              ).avatar,
            };
          });
          this.showChatParticipants = true;
          break;
        }
        case "removeUser": {
          this.resetForms();
          const membersListData = await restAdapter.get(
            `/api/chats/${this.collectionId}/view_members_remove?chat_id=${this.currentRoom.room.roomId}`
          );

          this.membersList = membersListData.data.member_list;

          this.membersList = this.membersList.map((member) => {
            return {
              ...member,
              avatar: this.assignedSuppliers.find(
                (supplier) => supplier.id === member.id
              ).avatar,
            };
          });
          if (!this.membersList.length) {
            notification.error("There are no companies available to remove.");
            break;
          }
          this.showRemoveUserForm = true;
          break;
        }
      }
      this.fetchRooms();
    },
    async inviteUserHandler() {
      this.resetForms();
      const membersListData = await restAdapter.get(
        `/api/chats/${this.collectionId}/view_members?chat_id=${this.currentRoom.room.roomId}`
      );
      this.membersList = membersListData.data.member_list;
      this.membersList = this.membersList.map((member) => {
        return {
          ...member,
          avatar: this.assignedSuppliers.find(
            (supplier) => supplier.id === member.id
          ).avatar,
        };
      });
      if (!this.membersList.length) {
        notification.error("There are no companies available to add.");
        return;
      }
      this.showInviteUserForm = true;
    },
    async deleteMessage({ roomId, message }) {
      const confirmDeletion = confirm(
        "Are you sure you want to delete this message? This action cannot be undone."
      );
      if (!confirmDeletion) return;

      try {
        const response = await restAdapter.delete(
          `/api/chat/messages/${message._id}/delete`
        );

        if (response.data.success) {
          // Handle file-related updates
          message.fileId && this.fetchFiles();
        } else {
          alert(response.data.message); // Show message from server (e.g., 60-minute restriction)
        }
      } catch (error) {
        console.error("Failed to delete message:", error);
        alert("Something went wrong. Please try again.");
      }
    },
    async unpinMessage(messageId) {
      await restAdapter.delete(`/api/chat/messages/${messageId}/unpin`);
      this.pinnedMessages = this.pinnedMessages.filter(
        (msg) => msg._id !== messageId
      );
    },
    showCreateChat() {
      this.resetForms();

      this.membersList = this.assignedSuppliers
        .filter(
          (supplier) =>
            supplier.id !== auth.user.account.organization_registry.id
        )
        .map((item) => ({ ...item }));

      if (!this.membersList.length) {
        notification.error(
          "There are no companies available to create one to one chat with."
        );
        return;
      }
      this.showCreateChatForm = true;
      this.currentSection = "selectUsers";
    },
    async getRoomParticipants() {
      if (!this.currentRoom?.room?.roomId) {
        return;
      }
      this.roomParticipants = [];
      const membersListData = await restAdapter.get(
        `/api/chats/${this.collectionId}/view_participants?chat_id=${this.currentRoom.room.roomId}`
      );

      this.roomParticipants = membersListData.data.member_list;

      this.roomParticipants = this.roomParticipants.map((member) => {
        return {
          ...member,
          avatar: this.assignedSuppliers.find(
            (supplier) => supplier.id === member.id
          ).avatar,
        };
      });
    },
    async createChat() {
      if (!this.roomName) {
        this.errorMessages.roomName = "Please enter a room name";
        return;
      }

      if (this.roomName.length > 40) {
        this.errorMessages.roomName = "Room name cannot exceed 40 characters.";
        return;
      }

      this.creatingChat = true;

      try {
        const createChatUserId = this.selectedUsersIds[0];
        const inviteChatUserIds = this.selectedUsersIds.slice(1);

        const newChatResponse = await restAdapter.post(
          `/api/collections/${this.collectionId}/create_chat`,
          {
            member_id: createChatUserId,
            name: this.roomName,
          }
        );

        if (!newChatResponse) return;

        const newChatRoomId = newChatResponse.data.chat.id;

        if (inviteChatUserIds.length) {
          for (const userId of inviteChatUserIds) {
            await restAdapter.post(`/api/chats/${newChatRoomId}/add_member`, {
              member_id: userId,
            });
          }
        }

        notification.success("ChatRoom successfully created");
        this.fetchRooms();
        this.resetForms();
      } catch (error) {
        notification.error("Failed to create chat room. Please try again.");
      } finally {
        this.creatingChat = false;
      }
    },
    goToAddRoomName() {
      if (this.selectedList.length >= 2) {
        this.currentSection = "addRoomName";
      } else {
        this.errorMessages.selectUsers = true;
      }
      this.isInitialCall = true;
    },
    backToPreviousSection() {
      this.errorMessages.roomName = false;
      this.isInitialCall = false;
      this.currentSection = "selectUsers";
    },
    openFileHandler({ message, action }) {
      switch (action) {
        case "preview":
          window.open(
            `${conf.server.host}/api/inquiries/file_show/${message.fileId}`
          );
          break;
        case "download":
          window.open(
            `${conf.server.host}/api/inquiries/file_download/${message.fileId}`
          );
          break;
      }
    },
    formatMessage(message, isPinMessage = false) {
      const formattedMessage = {
        _id: message.id,
        senderId: message.created_by || 0,
        system: !message.created_by,
        username: message.first_name
          ? message.first_name + " - " + message.company
          : "",
        content: message.message || "",
        avatar:
          message.created_by && message.avatar
            ? `${conf.server.host}/api/user/avatar/${message.created_by}`
            : !message.created_by
            ? null
            : this.person,
        date: this.$moment(message.created_at).format("D MMM"),
        name: message.attachment_name,
        timestamp: this.$moment(message.created_at).format("hh:mm A"),
        createdAt: message.created_at,
        deleted: message.deleted_at && message.deleted_at !== null,
        fileId: message.attachment_id,
        seen: message.seen,
        pageId: this.pagination,
        ...(isPinMessage
          ? { canUnpin: message.pinned_by == auth.user.id }
          : {}),
      };

      if (message.reply_id) {
        const replyTo = message.reply_to;
        const reply = {
          _id: message.reply_id,
          content: (replyTo && replyTo.message) || "",
        };
        if (replyTo && replyTo.attachment_id) {
          reply.file = {
            type: replyTo.mime_type,
            name: replyTo.title,
            extension:
              replyTo.attachment_id && replyTo.attachment_id.split(".").pop(),
            url: `${conf.server.host}/api/inquiries/file_show/${replyTo.attachment_id}`,
          };
        }

        formattedMessage.replyMessage = reply;
      }
      if (message.attachment_id) {
        if (message.mime_type) {
          formattedMessage.file = {
            name: message.attachment_name,
            extension: message.attachment_id.split(".").pop(),
            type: message.mime_type
              ? message.mime_type
              : message.file_mime_type,
            url: `${conf.server.host}/api/inquiries/file_show/${message.attachment_id}`,
            // url: message.attachment_url,
            fileName: message.attachment_id,
          };
        } else {
          formattedMessage.deleted = true;
        }
      }
      return formattedMessage;
    },
    async inviteUser() {
      this.creatingChat = true;
      if (!this.selectedUsersIds.length) {
        notification.error("Please select a company to add to the chat");
        this.creatingChat = false;
        return;
      } else {
        for (const userId of this.selectedUsersIds) {
          await restAdapter.post(
            `/api/chats/${this.currentRoom.room.roomId}/add_member`,
            {
              member_id: userId,
            }
          );
        }
        this.fetchRooms();
        this.resetForms();
        notification.success("Company successfully added");
        this.getRoomParticipants();
        this.creatingChat = false;
      }
    },
    async removeUser() {
      this.creatingChat = true;

      if (!this.selectedUsersIds.length) {
        notification.error("Please select a company to remove from the chat");
        this.creatingChat = false;
        return;
      } else {
        for (const userId of this.selectedUsersIds) {
          await restAdapter.delete(
            `/api/chats/${this.currentRoom.room.roomId}/remove_member`,
            {
              member_id: userId,
            }
          );
        }
        this.fetchRooms();
        this.resetForms();
        this.getRoomParticipants();
        notification.success("Company successfully removed");
        this.creatingChat = false;
      }
    },
    async renameRoom(room) {
      if (!room || !room.name) {
        return;
      }

      this.rooms = this.rooms.map((data) => {
        if (data.roomId === room.roomId) {
          return {
            ...data,
            roomName: room.name,
          };
        }
        return data;
      });
      restAdapter
        .put(`/api/chats/${room.roomId}/edit`, {
          name: room.name,
        })
        .then(() => {
          notification.success("Chat renamed successfully");
          this.resetForms();
          this.fetchRooms();
        });
    },
    resetForms() {
      this.isInitialCall = false;
      this.showInviteUserForm = false;
      this.showChatParticipants = false;
      this.showRemoveUserForm = false;
      this.showCreateChatForm = false;
      this.showRenameRoomForm = false;
      this.showSeenListPopup = false;

      this.selectedUsersIds = [];
      this.selectedList = [];

      this.errorMessages.selectUsers = false;
      this.errorMessages.roomName = false;
    },
    getChatImage(room) {
      if (
        room.chat_type === 1 ||
        room.chat_type === 4 ||
        (room.chat_type === 3 && room.members.length <= 2)
      ) {
        return this.directChatImage;
      } else {
        return this.commonChatImage;
      }
    },
    debounceSearch: debounce(async function () {
      const messagesResponse = await restAdapter.get(
        `/api/chats/${this.currentRoom.room.roomId}/find_messages?key=${this.searchMessage}`
      );
      this.matchingMessages = await messagesResponse.data.data
        .filter((message) => !message.deleted_at)
        .map((message) => this.formatMessage(message));
      this.searching = false;
    }, 1000),
    async searchChatMessages() {
      if (this.searchMessage) {
        this.searching = true;
        await this.debounceSearch();
      } else {
        this.matchingMessages = [];
      }
    },
    async showSelectedMessage(message, room = null) {
      this.activeChatTab = this.chatTabs.CHAT;
      this.scrolling = true;
      this.searchMessage = null;
      this.messagesLoaded = false;
      let messagesResponse;
      try {
        this.messagesFetched = false;
        messagesResponse = await restAdapter.get(
          `/api/chats/messages/${message._id}/search`
        );
        this.messagesFetched = true;
      } catch (error) {
        this.messagesFetched = true;
        if (room) {
          if (!room.options) {
            room.options = {};
          }
          room.options.reset = true;
          this.notFound = true;
          this.scrolling = false;
          this.fetchMessages(room);
          setTimeout(() => {
            this.notFound = false;
          }, 2000);
        }
        return null;
      }

      if (messagesResponse.data.no_record) {
        this.notFound = true;
        this.messagesLoaded = true;
        this.scrolling = false;
        this.fetchMessages(room);
        setTimeout(() => {
          this.notFound = false;
        }, 2000);
        return null;
      }

      this.messages = [];
      this.matchingMessages = [];

      const formattedMessages = await messagesResponse.data.messages.map(
        (message) => this.formatMessage(message)
      );
      if (!messagesResponse.data.messages.length) {
        this.messagesLoaded = true;
      }
      this.messages = formattedMessages;
      this.pagination = messagesResponse.data.page;

      this.searchingForMessage = message;
      setTimeout(() => {
        this.scrollToMessage(message);
      }, 1000);
    },
    limitSizeExceeded() {
      notification.error("Files size limit exceeded");
    },
    addUserToSelectedList(user) {
      this.selectedUsersIds.push(user.id);
      this.selectedList.push(user);
    },
    removeUserFromSelectedList(user) {
      this.selectedList = this.selectedList.filter(
        (selected) => selected.id !== user.id
      );
      this.selectedUsersIds = this.selectedUsersIds.filter(
        (id) => id !== user.id
      );
    },
    async sendSeenStatus(roomId, lastSeenMsg = null) {
      if (
        this.updateSeen &&
        this.currentRoom.room.roomId === roomId &&
        ((this.$route.name == routes.COLLECTION &&
          this.$route.params.id == this.collectionId) ||
          (this.activeCard && this.activeCard == this.collectionId))
      ) {
        await restAdapter.put(`/api/chats/${roomId}/update_seen`, {
          lastSeenMsg: lastSeenMsg,
        });
      }
    },
    updateSeenStatus(roomId, lastSeenMsg = null) {
      if (this.currentRoom.room.roomId === roomId) {
        if (lastSeenMsg) {
          this.messages.forEach((msg) => {
            if (msg._id === lastSeenMsg) {
              msg["seen"] = true;
            }
          });
        }
      }
    },
    scrollToMessage(message) {
      if (!this.messagesFetched) {
        return;
      }
      this.searchingForMessage = false;
      document.getElementById(message._id).scrollIntoView();
      document.getElementById(message._id).style.backgroundColor =
        "rgba(0, 0, 0, 0.3)";
      this.scrolling = false;
      setTimeout(() => {
        document.getElementById(message._id).style.backgroundColor = null;
      }, 6000);
    },
  },
  computed: {
    chatRoomName() {
      const roomName = this.currentRoom?.room?.roomName;
      return roomName;
    },
    nonSelectedList() {
      let nonSelected = [];
      nonSelected = this.membersList.filter((user) => {
        return !this.selectedList.includes(user);
      });
      return nonSelected;
    },
  },
};
</script>

<style lang="scss" scoped>
#chat {
  text-align: left;
}
.search-result {
  position: absolute;
  right: 0px;
  padding: 5px;
  z-index: 99;
  margin: 0px;
  background: white;
  box-shadow: 0 3px 6px rgba(71, 48, 104, 0.06);
  width: 300px;
}
.search-results-container {
  overflow-y: scroll;
  max-height: 300px;
  padding: 0 8px;
}
.search-result-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
  padding: 5px;
  margin-bottom: 2px;

  &:hover {
    background: #f5f5f5;
  }
}
.search-result-item-text {
  flex: 1;
  margin-left: 15px;
  color: $primaryDarkest;
  overflow: hidden;

  div:first-child,
  div:last-child {
    font-weight: 600;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  div:last-child {
    font-weight: 400;
    font-size: 12px;
    color: $primaryDark;
  }
}
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.spinner-box {
  position: relative;
}
.notFound-alert {
  color: red;
}
.container {
  overflow-y: scroll;
  max-height: 220px;
  position: absolute;
  width: 100%;
  z-index: 99;
}
.createChatButton {
  padding: 30px;
}
input:focus {
  outline: none;
}
::placeholder {
  color: $primaryDark;
  font-weight: 400;
}
.search-message-field {
  display: flex;
  width: 180px;
  height: 32px;
  background: $paginateText;
  border-radius: 37px;
  align-items: center;
  padding: 0px 8px;
  justify-content: space-between;
  border: 1px solid #473068;
  border-radius: 16px;

  ::placeholder {
    color: rgba(71, 48, 104, 0.7);
  }
}
.search-icon {
  color: "#7E6E95";
}
.text-field[type="text"],
select {
  height: 40px;
  width: 100%;
  border: 1px solid $primaryDark;
  border-radius: 6px;
  color: #492a67;
  padding: 0 10px;
  margin: 25px 0 0;
}
.text-field:focus {
  outline: none;
}
.error-message {
  font-size: 12px;
  color: red;
  margin: 25px 0 -20px;
}
.search-messages {
  position: relative;
}
.room-members-button {
  border-radius: 5px;
  padding: 2px 2px 2px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background: rgba(227, 222, 235, 0.46);
    padding: 3px 3px 3px 13px;
  }

  .participant-icon {
    .v-icon,
    .v-avatar {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: white;
      border: 1px solid $primaryDarkest;
      margin-left: -10px;
    }
  }

  .participants-length {
    padding-left: 5px;
  }
}
</style>
